<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="状态">
          <el-select v-model="searchForm.status" placeholder="请选择状态" size="small" clearable>
            <el-option label="已排查" :value="1"></el-option>
            <el-option label="未排查" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单位名称">
          <el-input v-model="searchForm.unitName" placeholder="请输入单位名称" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="warningList" stripe style="width: 100%" height="100%">
        <el-table-column prop="unitName" label="单位名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="legalName" label="法人姓名" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="legalPhone" label="法人电话" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="type" label="故障事件类型" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag type="warning" size="small" v-show="scope.row.type===0">设备故障等</el-tag>
            <el-tag type="warning" size="small" v-show="scope.row.type===1">其他</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag type="success" size="small" v-show="scope.row.status===1">已排查</el-tag>
            <el-tag type="danger" size="small" v-show="scope.row.status===0">未排查</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="故障内容" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="startDate" label="故障出现时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.alarmDate|timeFormat}}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip content="消除" placement="top" effect="dark">
              <el-button type="danger" size="small" circle icon="el-icon-delete" @click="clearFault(scope.row)" :disabled="scope.row.status===1"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentPage: 1,
      total: 0,
      warningList: [],
      typeList: [],
      searchForm: {}
    }
  },
  created () {
    this.initTable()
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    async initTable () {
      const data = {}
      if (this.searchForm.unitName) {
        data.unitName = this.searchForm.unitName
      }
      data.status = this.searchForm.status
      data.pageNo = this.currentPage
      data.pageSize = 10
      const { data: result } = await this.$axios.get('/failureEvent/list', { params: data })
      if (result.code === 200) {
        this.warningList = result.data.result
        this.total = result.data.total
      } else {
        this.$message.error(result.msg)
      }
    },
    async clearFault (row) {
      const confirm = await this.$confirm('此操作将排查该故障, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.post(`/failureEvent/${row.id}`)
        if (result.code === 200) {
          this.$message.success('排查成功')
          this.initTable()
        } else {
          this.$message.success(result.msg)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}
.dialog {
  margin: 0;
  padding: 10px;
  ::v-deep  .el-dialog__header {
    display: none !important;
  }
}
.demo-table-expand {
  font-size: 0;
  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ::v-deep  label {
      color: #99a9bf !important;
    }
  }
}
::v-deep  .el-table__expanded-cell {
  padding: 0 50px;
}
</style>
